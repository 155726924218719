"use strict";
const body = document.body;
Maska.create(".masked");

// Popups data
const successPopup = document.getElementById("success-popup");
const errorPopup = document.getElementById("error-popup");

function releasePopupPosition() {
  document.body.classList.remove("lock");
  body.style.top = "";
  window.scrollTo(0, body.dataset.scroll);
  body.removeAttribute("data-scroll");
}

[successPopup, errorPopup].forEach((elem) => {
  elem.addEventListener("click", () => {
    releasePopupPosition();

    switch (elem.id) {
      case "success-popup":
        successPopup.classList.add("invisible");
        break;
      case "error-popup":
        errorPopup.classList.add("invisible");
        break;
    }
  });
});

const successWrapper = document.getElementById("success-wrapper");
successWrapper.addEventListener("click", (e) => {
  e.stopPropagation();

  if (e.target.closest("#success-btn")) {
    releasePopupPosition();
    successPopup.classList.add("invisible");
  }
});

const errorWrapper = document.getElementById("error-wrapper");
errorWrapper.addEventListener("click", (e) => {
  e.stopPropagation();

  if (e.target.closest("#error-btn")) {
    releasePopupPosition();
    errorPopup.classList.add("invisible");
  }
});

// Form Handler
const submitButton = document.getElementById("submit-btn");

function resetForm() {
  nameInput.value = "";
  birthDateInput.value = "";
  phoneInput.value = "";
  emailInput.value = "";

  submitButton.disabled = true;
  inputWrappers.forEach((wrapper) => {
    wrapper.classList.remove("has-success");
  });
}

function buttonDisabledHandler() {
  let successCounter = 0;
  inputWrappers.forEach((wrapper) => {
    if (wrapper.classList.contains("has-success")) successCounter++;
  });

  if (successCounter === 4) {
    submitButton.disabled = false;
  } else {
    submitButton.disabled = true;
  }
}

const inputWrappers = document.querySelectorAll(".form__input-wrapper");
const inputs = document.getElementsByTagName("input");
Array.from(inputs).forEach((input) => {
  input.addEventListener("blur", (e) => {
    const input = e.target;
    const wrapper = input.closest(".form__input-wrapper");
    const emptyError = wrapper.querySelector(".empty-error");

    if (input.value.length === 0) {
      input.classList.add("error-border");
      emptyError.classList.add("empty-error-visible");
    }
  });
});

// Name
function nameIsValid(nameValue) {
  return nameValue.match(
    /^[А-ЯЁA-Z][а-яёa-z]{0,}([-][А-ЯЁA-Z][а-яёa-z]{0,})?\s[А-ЯЁA-Z][а-яёa-z]{0,}([-][А-ЯЁA-Z][а-яёa-z]{0,})?/
  ) && nameValue.length < 50
    ? true
    : false;
}

const nameInput = document.getElementById("name");
nameInput.addEventListener("input", (e) => {
  const name = e.target;
  const wrapper = name.closest(".form__input-wrapper");

  const emptyError = wrapper.querySelector(".empty-error");
  const incompleteError = wrapper.querySelector(".incomplete-error");

  if (name.value.length === 0) {
    name.classList.add("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.add("empty-error-visible");
    wrapper.classList.remove("has-success");
    buttonDisabledHandler();
    return;
  }

  if (nameIsValid(name.value)) {
    wrapper.classList.add("has-success");
    name.classList.remove("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.remove("empty-error-visible");
    buttonDisabledHandler();
    return;
  }

  emptyError.classList.remove("empty-error-visible");
  incompleteError.classList.add("incomplete-error-visible");
  name.classList.add("error-border");
  wrapper.classList.remove("has-success");
  buttonDisabledHandler();
});

// Date
function dateIsValid(day, month, year, dateValue) {
  return Number(day) <= 31 &&
    Number(month) <= 12 &&
    Number(year) >= 1900 &&
    Number(year) < 2015 &&
    dateValue.length === 10
    ? true
    : false;
}

const birthDateInput = document.getElementById("date");
birthDateInput.addEventListener("input", (e) => {
  const date = e.target;
  const wrapper = date.closest(".form__input-wrapper");

  const emptyError = wrapper.querySelector(".empty-error");
  const incompleteError = wrapper.querySelector(".incomplete-error");

  const day = date.value.split(".")[0];
  const month = date.value.split(".")[1];
  const year = date.value.split(".")[2];

  if (date.value.length === 0) {
    date.classList.add("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.add("empty-error-visible");
    wrapper.classList.remove("has-success");
    buttonDisabledHandler();
    return;
  }

  if (dateIsValid(day, month, year, date.value)) {
    wrapper.classList.add("has-success");
    date.classList.remove("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.remove("empty-error-visible");
    buttonDisabledHandler();
    return;
  }

  emptyError.classList.remove("empty-error-visible");
  incompleteError.classList.add("incomplete-error-visible");
  date.classList.add("error-border");
  wrapper.classList.remove("has-success");
  buttonDisabledHandler();
});

// Phone
function isPhoneValid(phoneValue) {
  return phoneValue.length === 18 ? true : false;
}

const phoneInput = document.getElementById("phone");
phoneInput.addEventListener("input", (e) => {
  const phone = e.target;
  const wrapper = phone.closest(".form__input-wrapper");

  const emptyError = wrapper.querySelector(".empty-error");
  const incompleteError = wrapper.querySelector(".incomplete-error");

  if (phone.value.length === 0) {
    phone.classList.add("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.add("empty-error-visible");
    wrapper.classList.remove("has-success");
    buttonDisabledHandler();
    return;
  }

  if (isPhoneValid(phone.value)) {
    wrapper.classList.add("has-success");
    phone.classList.remove("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.remove("empty-error-visible");
    buttonDisabledHandler();
    return;
  }

  emptyError.classList.remove("empty-error-visible");
  incompleteError.classList.add("incomplete-error-visible");
  phone.classList.add("error-border");
  wrapper.classList.remove("has-success");
  buttonDisabledHandler();
});

// Email
function isEmailValid(emailValue) {
  return emailValue.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ) &&
    emailValue.length < 50 &&
    emailValue.split("@")[1] !== "yandex.com" &&
    emailValue.split("@")[1] !== "yandex.co" &&
    emailValue.split("@")[1] !== "gmail.ru" &&
    emailValue.split("@")[1] !== "gmail.co" &&
    emailValue.split("@")[1] !== "mail.co" &&
    emailValue.split("@")[1] !== "mail.com"
    ? true
    : false;
}

const emailInput = document.getElementById("email");
emailInput.addEventListener("input", (e) => {
  const email = e.target;
  const wrapper = email.closest(".form__input-wrapper");

  const emptyError = wrapper.querySelector(".empty-error");
  const incompleteError = wrapper.querySelector(".incomplete-error");

  if (email.value.length === 0) {
    email.classList.add("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.add("empty-error-visible");
    wrapper.classList.remove("has-success");
    buttonDisabledHandler();
    return;
  }

  if (isEmailValid(email.value)) {
    wrapper.classList.add("has-success");
    email.classList.remove("error-border");
    incompleteError.classList.remove("incomplete-error-visible");
    emptyError.classList.remove("empty-error-visible");
    buttonDisabledHandler();
    return;
  }

  emptyError.classList.remove("empty-error-visible");
  incompleteError.classList.add("incomplete-error-visible");
  email.classList.add("error-border");
  wrapper.classList.remove("has-success");
  buttonDisabledHandler();
});

function fixPopupPosition() {
  const height = window.scrollY;
  body.dataset.scroll = height;

  body.classList.add("lock");
  body.style.top = `-${height}px`;
}

submitButton.addEventListener("click", () => {
  const day = birthDateInput.value.split(".")[0];
  const month = birthDateInput.value.split(".")[1];
  const year = birthDateInput.value.split(".")[2];

  const nameValid = nameIsValid(nameInput.value);
  const dateValid = dateIsValid(day, month, year, birthDateInput.value);
  const phoneValid = isPhoneValid(phoneInput.value);
  const emailValid = isEmailValid(emailInput.value);

  fixPopupPosition();
  submitButton.disabled = true;
  submitButton.innerHTML = `Отправляется <span class="submit-spinner"></span>`;

  if (!nameValid || !dateValid || !phoneValid || !emailValid) {
    const wrappers = document.querySelectorAll(".form__input-wrapper");
    wrappers.forEach((wrapper) => {
      wrapper.classList.remove("has-success");
    });
    resetForm();
    submitButton.innerHTML = `Отправить заявку`;
    errorPopup.classList.remove("invisible");
    return;
  }

  const form = {
    name: nameInput.value,
    birthDate: birthDateInput.value,
    phone: phoneInput.value,
    email: emailInput.value,
    source: "reinvest_page",
  };

  exponea.track(
    "send-request",
    form,
    () => {
      submitButton.innerHTML = `Отправить заявку`;
      successPopup.classList.remove("invisible");
      resetForm();
    },
    () => {
      submitButton.innerHTML = `Отправить заявку`;
      errorPopup.classList.remove("invisible");
    }
  );
});
